import React from "react"
import { Link } from "gatsby"
import {
    Section,
    Container,
    Title,
    Subtitle,
    Button,
    Icon,
    Column,
    Columns,
} from "bloomer"

import Layout from "../components/layout"
import SEO from "../components/seo"

const StartupPage = () => (
    <Layout>
        <SEO title="Startups vs COVID-19" />
        <Section className="has-background-white">
            <Container>
                <Columns>
                    <Column style={{ margin: '0 auto' }} isSize="3/4">
                        {/* Page title */}
                        <Title isSpaced><a href="https://www.startupsvscovid.com" target="_blank">
                            Startups vs COVID-19</a>
                        </Title>
                        <Subtitle>A repository of resources for Indian startups solving challenges created by COVID-19</Subtitle>
                        <p>Startup vs COVID consolidates all efforts being taken by investors, government departments and stakeholders in the startup ecosystem to support startups tackling issues pertaining to COVID-19.</p>
                        <br></br>
                        <div className='my2' style={{ margin: '10px auto 40px' }}>
                            <p>Built Using: &nbsp;
                            <a href="https://airtable.com" target="_blank">
                                    Airtable</a>,
                                    &nbsp;
                            <a href="https://www.gatsbyjs.org/" target="_blank">
                                    GatsbyJS</a> &
                                    &nbsp;
                            <a href="https://www.netlify.com/" target="_blank">
                                    Netlify</a>
                            </p>
                        </div>


                        <Columns>
                            <Column isSize="1/2">
                                <div className="inline-block xs-mb2 lg-pb2 md-pb2 xs-pb1 lg-flex-auto items-center center"><blockquote class="twitter-tweet"><p lang="en" dir="ltr">[1] We have created a platform (<a href="https://t.co/TdNSakxRys">https://t.co/TdNSakxRys</a>) where we have consolidated all efforts being taken by investors, government departments, and stakeholders in the startup ecosystem to support startups tackling this issue. <a href="https://twitter.com/hashtag/startupsvscovid?src=hash&amp;ref_src=twsrc%5Etfw">#startupsvscovid</a> <a href="https://twitter.com/91springboard?ref_src=twsrc%5Etfw">@91springboard</a></p>&mdash; Pranay Gupta (@PranayG) <a href="https://twitter.com/PranayG/status/1243454437005254658?ref_src=twsrc%5Etfw">March 27, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></div>
                            </Column>

                            <Column isSize="1/2">
                                <div className="inline-block xs-mb2 lg-pb2 md-pb2 xs-pb1 lg-flex-auto items-center"><blockquote class="twitter-tweet"><p lang="en" dir="ltr"><a href="https://twitter.com/hashtag/AIM?src=hash&amp;ref_src=twsrc%5Etfw">#AIM</a> in collaboration with <a href="https://twitter.com/91springboard?ref_src=twsrc%5Etfw">@91springboard</a> has launched an online platform to consolidate database of startup solutions, information regarding efforts taken by investors, Govt authorities &amp; other stakeholders to tackle <a href="https://twitter.com/hashtag/Covid_19?src=hash&amp;ref_src=twsrc%5Etfw">#Covid_19</a>: <a href="https://t.co/BMuzpdctyT">https://t.co/BMuzpdctyT</a><a href="https://t.co/8MW5LQ75jV">https://t.co/8MW5LQ75jV</a></p>&mdash; AIM (@AIMtoInnovate) <a href="https://twitter.com/AIMtoInnovate/status/1244582461419315205?ref_src=twsrc%5Etfw">March 30, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></div>
                            </Column>
                        </Columns>



                        <div className="flex flex-row align-center md-justify-between lg-justify-between xs-justify-center flex-wrap max-width-4 mx-auto">
                        </div>
                        <div >
                            <div style={{ 'textAlign': 'center', margin: '20px 0' }}>Featured on</div>
                            <div style={{ justifyContent: 'space-between', marginBottom: '40px' }} className="logo-centre flex flex-row  flex-wrap max-width-4 mx-auto">
                                <a href="https://www.entrepreneur.com/article/348353" target="_blank"><img className="mx2 inline-block xs-mb2 lg-pb2 md-pb2 xs-pb1" style={{ width: "150px" }} src={'/entrepreneur.png'} /></a>
                                <a href="https://www.thehindubusinessline.com/info-tech/91springboard-launches-start-ups-vs-covid-19-initiative/article31189616.ece" target="_blank"><img className="mx2 inline-block xs-mb2 lg-pb2 md-pb2 xs-pb1" style={{ width: "150px" }} src={'/hbl1.jpg'} /></a>
                                <a href="https://www.techcircle.in/2020/03/30/91springboard-launches-covid-19-information-repository-for-startups" target="_blank"><img className="mx2 inline-block xs-mb2 lg-pb2 md-pb2 xs-pb1" style={{ width: "150px" }} src={'/techc.png'} /></a>

                            </div>
                        </div>
                        {/* Back to homepage button */}
                        <Link to="/">
                            <Button isColor="primary" className="is-rounded" id="is-spaced">
                                <Icon className="fa fa-arrow-left fa-sm" />
                                <span>Go back</span>
                            </Button>
                        </Link>
                    </Column>
                </Columns>
            </Container>
        </Section>
    </Layout >
)

export default StartupPage
